/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import "theme/buttons.scss";
@import "theme/cards.scss";
@import "theme/grid.scss";
@import "theme/forms.scss";
@import "theme/material.scss";
@import "theme/modals.scss";
@import "theme/tabs.scss";

@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import url('https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap');


@layer base {
  input {
    &:focus {
      @apply outline-none shadow-none;
      box-shadow: none !important;
    }
  }
}

.sd-rating.sd-rating--wrappable {
  fieldset {
    flex-direction: column !important;
  }
}

.noto-color-emoji-regular {
  font-family: "Noto Color Emoji", sans-serif;
  font-weight: 400;
  font-style: normal;
}

app-header {
  position: relative;
  z-index: 1010;
}
.full-width {
  width: 100%;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}
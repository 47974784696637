ion-tabs {
  &.lifestyle {
    ion-tab-button {
      --color-focused: var(--my-blue-on-surface);
      --color-selected: var(--my-blue-on-surface);

      &.tab-selected {
        border-color: var(--my-emerald);
       --background: var(--off-green-100);
      }
      border-bottom: 2px solid transparent;
      --ripple-color: transparent;
    }
  }

  &.monitoring {
    ion-tab-button {
      --color-focused: var(--my-blue-on-surface);
      --color-selected: var(--my-blue-on-surface);

      &.tab-selected {
        border-color: var(--my-light-blue);
       --background: var(--solitude-50);
      }
      border-bottom: 2px solid transparent;
      --ripple-color: transparent;
    }
  }

  &.counseling {
    ion-tab-button {
      --color-focused: var(--my-blue-on-surface);
      --color-selected: var(--my-blue-on-surface);

      &.tab-selected {
        border-color: var(--my-light-yellow);
       --background: var(--old-lace-50);
      }
      border-bottom: 2px solid transparent;
      --ripple-color: transparent;
    }
  }
}
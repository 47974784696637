@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'material-symbols';

$primary: (
    50 : #e6e9ed,
    100 : #c0c8d3,
    200 : #96a3b6,
    300 : #6b7e98,
    400 : #4c6382,
    500 : #2c476c,
    600 : #274064,
    700 : #213759,
    800 : #1b2f4f,
    900 : #10203d,
    A100 : #79a3ff,
    A200 : #4680ff,
    A400 : #135dff,
    A700 : #004ef8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);


$app-primary: mat.define-palette($primary, 500);
$app-accent:  mat.define-palette($primary, 500);
$app-warn:    mat-palette($mat-red);

$app-typography: mat.define-typography-config(
    $font-family: Lato,
    $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em, $font-family: 'Quicksand'),
    $headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em, $font-family: 'Quicksand'),
    $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em, $font-family: 'Quicksand'),
);

$app-theme: mat-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  ),
  typography: $app-typography

));


@include angular-material-theme($app-theme);


mat-form-field {
  &.monitoring {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: #E9F5FF !important;
    }
  }
}

.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  background-color: #fff;
  color: #000000de
}

.mat-calendar-body-selected {
  background-color: #007f51;
  color: #fff;
}

.mat-calendar-body-cell-content {
  border: none !important;
}

ion-fab-button[data-desc] {
  position: relative;
}

ion-fab-button[data-desc]::after {
  @apply bg-[#2c476c] text-white rounded-full;

  position: absolute;
  content: attr(data-desc);
  z-index: 1;
  right: 55px;
  bottom: 4px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}
ion-card {
  box-shadow: none;
  border-radius: 28px;
  padding: 24px;

  &.lifestyle {
    background-color: var(--my-dark-green) !important;
  }

  &.monitoring {
    background: #E9F5FF !important;
  }

  &.counseling {
    @apply bg-old-lace-50;
  }

  ion-datetime.lifestyle {
    --background: var(--my-dark-green);
    color: var(--my-blue);

    &::part(calendar-day active) {
      background: var(--my-blue);
      color: white;
    }

    &::part(calendar-day active)::focus {
     box-shadow: none;
    }

    &::part(calendar-day today) {
      border-color: var(--my-blue);
    }
  }
}

mat-radio-button {
  .mdc-form-field {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: space-between;
    align-items: center;
  }
}

mat-checkbox {
  &.monitoring {
    background: #E9F5FF !important;
    padding: 1rem;
    margin-bottom: 1.5rem;
  }

  &.mdc-reverse {
    .mdc-form-field {
      display: flex;
      flex-direction: row-reverse !important;
      justify-content: space-between;
      align-items: center;
  
      .mdc-label {
        display: flex;
        align-items: center;
      }
    }
  }
}